#services .card-img-overlay{
    height: 225px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
}

#services .album{
    height: 50vh;
    overflow-y: auto;
}