.bg-primary-keyma{
  background: #1e2375!important;
}

.bg-dark-keyma{
  background: #232323!important; 
}

.bg-info-keyma{
  background: #f5951a!important;
}

.color-info-keyma{
  color: #f5951a!important;
}

.color-primary-keyma{
  color: #1e2375!important;
}

.line-primary{
  width: 200px;
  height: 10px;
  background: #f5951a;
  
  margin-bottom: 3%;
}

.line-end-info{
  width: 50%;
  height: 10px;
  background: #f5951a;  
  margin-bottom: 3%;
}

.line-end-primary{
  width: 50%;
  height: 10px;
  background: #1e2375;  
  margin-bottom: 3%;
}

.border-left-info{
  border-left: 1px solid #f5951a;
}


.logo-background{    
  padding: 1%;
  border: 5px solid #f5951a;
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70% ;
}

.logo{
  height: 50px;
}

@media only screen and (max-width: 750px) {
  .logokey {
    height: 100px !important;
  }
}