#sectionHead{
    
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
}

#sectionHead .col-lg-6{
    padding: 2%;
    background: rgba(250, 250, 250, 0.5);
    color: white;
}