#about{
    
    padding: 3rem 1rem 3rem 1rem;
}

#about small{
    font-size: 10px;
}

#about .small{
    font-size: 12px;
}



