#navbar .logo-background{    
    padding: 1%;
    border: 5px solid #ffb749;
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70% ;
}

#navbar .logo-background a{ 
    font-weight: bolder;
}

#navbar .navbar-nav li a{
    color: black!important;
}

#navbar .navbar{
    border-bottom: 1px solid rgba(83, 175, 244, 255);
}